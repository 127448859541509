<template>
<div class="wechat-auth">
    <van-nav-bar
        :title="title"
        fixed
        left-arrow
        @click-left="$router.go(-1)"
    />
    <div class="content">
      <img src="@/assets/imgs/icon64_appwx_logo.png" alt="">
      <div class="title">微信授权中</div>
      <van-loading class="loading" type="spinner" />
    </div>

</div>
  
</template>
<script>
// import { encodeURL } from 'js-base64';
import { Toast } from 'vant';
export default {
  name: "WeAuth",
  data() {
    return {
      loading: false,
      c: '', //用于获取微信用户信息的code(需先请求)
      i: '', //推荐码
      title: '微信授权',
      content: ''
    };
  },
  computed:{
    
  },
  components:{
  },
  mounted() {
    
    const paramsStr = window.location.search;
    const params = new URLSearchParams(paramsStr);
    let _code = params.get('code'); // list
    if(this.$store.state.referer){
      this.i = this.$store.state.referer;
    }else{
      this.i = params.get('i');
    }
    if(_code){
      this.c = _code;
      //携带code参数时，用code发起登录
      this.getAuth();
    }else{
      //没有code参数时，请求code参数
      this.getCode(window.location.href);
    }
  },
  methods: {
    getCode(r){
      this.loading = true;
      this.$http.call(this.$http.api.wecode,{
        params: {
          r: r, p: this.i
        }
      }).then(res=>{
        console.log(res);
        this.loading = false;
        let resp = res.data;
        if(resp.code=='0'){
          // this.content = resp.content;
          console.log(resp.url);
          window.location.href = resp.url;
        }else{
          Toast(resp.data);
        } 
      },function(res){
        console.log('something goes wrong...');
        console.log(res);
      }).then(()=>{
          
      });
    },
    getAuth(){
      this.loading = true;
      let _this = this;
      this.$http.call(this.$http.api.weauth,{
        data: {
          code: this.c,
          i: this.i
        }
      }).then(res=>{
        console.log(res);
        this.loading = false;
        let resp = res.data;
        if(resp.code=='0'){
          // this.content = resp.content;
          console.log(resp.user);
          if(resp.token){
            _this.$store.commit('setToken', resp.token.access_token);
            _this.$store.commit('setUserinfo', resp.user);
            _this.$router.push({name:'user'});
          }
        }else{
          Toast(resp.data);
        } 
      },function(res){
        console.log('something goes wrong...');
        console.log(res);
      }).then(()=>{
          
      });
    },
  },
};
</script>
<style lang="less" scoped>
.wechat-auth {
    padding: 50px 15px;
    .content {
      padding-top: 100px;
      text-align: center;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      .title {
        padding: 10px;
      }
    }
}
</style>
